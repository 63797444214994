import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL
axios.defaults.headers.get['x-functions-key'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.post['x-functions-key'] = process.env.VUE_APP_API_KEY

export const api = {
    getPaymentSummary: getPaymentSummary,
    getFeeLineData: getFeeLineData,
    getSagaFileData: getSagaFileData,
    retryPayment: retryPayment,
    closePayment: closePayment,
    getFileDownload: getFileDownload,
    getInvoiceFileDownload: getInvoiceFileDownload,
    getCSV: getCSV
}

async function getCSV(start, end, statusList, caseReference) {
    const periodDates = periodConverter(start, end)
    const periodParam = "?periodStart=" + periodDates.startDate + "&periodEnd=" + periodDates.endDate
    const statusListParam = "&statusList=" + statusList.join()
    const caseReferenceParam = caseReference ? "&caseReference=" + caseReference : ""
    return getHelperWithError("api/export-csv" + periodParam + statusListParam + caseReferenceParam)
}

async function getPaymentSummary(period) {
    const periodDates = summaryConverter(period)
    const periodParam = "?periodStart=" + periodDates.startDate + "&periodEnd=" + periodDates.endDate
    return getHelper("api/payment-summary" + periodParam)
}

async function getFeeLineData(start, end, statusList, caseReference, pageNumber, pageSize, invNum, order, asc) {
    const periodDates = periodConverter(start, end)
    const periodParam = "?periodStart=" + periodDates.startDate + "&periodEnd=" + periodDates.endDate
    const statusListParam = "&statusList=" + statusList.join()
    const caseReferenceParam = caseReference ? "&caseReference=" + caseReference : ""
    const pageNumberParam = "&pageNumber=" + pageNumber
    const pageSizeParam = "&pageSize=" + pageSize
    const invoiceParam = "&invNum=" + invNum
    const orderBy = "&orderBy=" + order
    const orderDir = "&orderDir=" + asc
    return getHelper("api/fee-line-search" + periodParam + statusListParam + caseReferenceParam + pageNumberParam + pageSizeParam + invoiceParam + orderBy + orderDir)
}

async function getSagaFileData(period, status, filter, pageNumber, pageSize) {
    const periodDates = summaryConverter(period)
    const periodParam = "?periodStart=" + periodDates.startDate + "&periodEnd=" + periodDates.endDate
    const statusParam = status ? "&downloaded=" + getDownloadedFlag(status) : ""
    const fileTypeParam = "&filter=" + filter
    const pageNumberParam = "&pageNumber=" + pageNumber
    const pageSizeParam = "&pageSize=" + pageSize
    return getHelper("api/file-download-search" + periodParam + statusParam + fileTypeParam + pageNumberParam + pageSizeParam)
}

async function retryPayment(caseReference, appointmentGuid, user) {
    return postHelper("api/replay-payment", { caseReference, appointmentGuid, user })
}

async function closePayment(caseReference, appointmentGuid, user) {
    return postHelper("api/close-payment", { caseReference, appointmentGuid, user })
}

async function getFileDownload(date, filter, user) {
    const dateParam = "?date=" + date
    const fileTypeParam = "&filter=" + filter
    const userParam = "&user=" + user
    return getHelper("api/download-zip-file" + dateParam + fileTypeParam + userParam)
}

async function getInvoiceFileDownload(invoiceNumber, user) {
    const invoiceNumberParam = "?invoiceNumber=" + invoiceNumber
    const userParam = "&user=" + user
    return getHelper("api/download-invoice-file" + invoiceNumberParam + userParam)
}

async function getHelperWithError(url) {
    let errorMessage = null;
    const response = await axios
        .get(url)
        .catch(function (error) {      
            if (error.response) {
                // The request was made and the server responded with a non-200 status code
                console.log(error.response.data)
                errorMessage = error.response.data.error ?? null
                console.log(error.response.data.error)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
            console.log(error)
        })
    if (errorMessage) {
        return { data: null, error: errorMessage }
    }
    return {data: response?.data, error: null}
}

async function getHelper(url) {
    const response = await axios
        .get(url)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a non-200 status code
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
            console.log(error)
        })

    return response?.data
}

async function postHelper(url, data) {
    const response = await axios
        .post(url, data)
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a non-200 status code
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message)
            }
            console.log(error)
        })

    return response?.data
}

function summaryConverter(period) {

    const yearMonthRegex = /^\d{4}-(0[1-9]|1[0-2])$/g

    if (period === "last 24 hrs") {
        const endDate = new Date()
        const startDate = new Date(endDate)
        startDate.setDate(endDate.getDate() - 1)
        return { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
    }
    else if (period === "this week") {
        const thisWeek = getThisWeekPeriod()
        return { startDate: thisWeek.startDate.toISOString(), endDate: thisWeek.endDate.toISOString() }
    }
    else if (period === "last week") {
        const thisWeek = getThisWeekPeriod()
        const lastMonday = thisWeek.startDate
        lastMonday.setTime(lastMonday.getTime() - (7 * 24 * 3600000))
        const lastSunday = new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate())
        lastSunday.setTime(lastSunday.getTime() + (7 * 24 * 3600000) - 1)
        return { startDate: lastMonday.toISOString(), endDate: lastSunday.toISOString() }
    }
    else if (period === "this month") {
        const endDate = new Date()
        const startDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), 1))
        return { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
    }
    else if (period.match(yearMonthRegex)) {
        const now = new Date(period)
        const startDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1))
        const endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 23, 59))
        return { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
    }
    else {
        throw "Invalid period!"
    }
}

function periodConverter(start, end) {
    if (start && end) {
        const first = new Date(start);
        const second = new Date(end);
        return { startDate: first.toISOString(), endDate: second.toISOString() }
    } else {
        const second = new Date()
        const first = new Date(second)
        first.setDate(second.getDate() - 1)
        return { startDate: first.toISOString(), endDate: second.toISOString() }
    }
}

function getThisWeekPeriod() {
    const endDate = new Date()
    const weekDate = new Date()

    while (weekDate.getDay() !== 1) {
        weekDate.setTime(weekDate.getTime() - (24 * 3600000))
    }

    const startDate = new Date(Date.UTC(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate()))
    return { startDate: startDate, endDate: endDate }
}


function getDownloadedFlag(status) {
    if (status === "all") return null
    return status === "downloaded before"
}

export default api
